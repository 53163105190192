@tailwind base;
@tailwind components;
@tailwind utilities;


/* Fonts */

@font-face {
    font-family: 'Circularstd';
    src: url('https://civilmantra.s3.ap-south-1.amazonaws.com/Saptagon-website-images/SaptagonAssets/Fonts/CircularStd-Medium.otf') format('otf');
    font-weight: normal;
    font-style: normal;
}
  
@font-face {
     font-family: 'Circularstd';
     src: url('https://civilmantra.s3.ap-south-1.amazonaws.com/Saptagon-website-images/SaptagonAssets/Fonts/CircularStd-MediumItalic.otf') format('otf');
     font-weight: normal;
     font-style: italic;
}

@font-face {
     font-family: 'Circularstd';
     src: url('https://civilmantra.s3.ap-south-1.amazonaws.com/Saptagon-website-images/SaptagonAssets/Fonts/CircularStd-Bold.otf') format('otf');
     font-weight: bold;
     font-style: normal;
}

@font-face {
     font-family: 'Circularstd';
     src: url('https://civilmantra.s3.ap-south-1.amazonaws.com/Saptagon-website-images/SaptagonAssets/Fonts/CircularStd-BoldItalic.otf') format('otf');
     font-weight: bold;
     font-style: italic;
}

@font-face {
     font-family: 'Circularstd';
     src: url('https://civilmantra.s3.ap-south-1.amazonaws.com/Saptagon-website-images/SaptagonAssets/Fonts/CircularStd-Black.otf') format('otf');
     font-weight: black;
     font-style: normal;
}

@font-face {
     font-family: 'Circularstd';
     src: url('https://civilmantra.s3.ap-south-1.amazonaws.com/Saptagon-website-images/SaptagonAssets/Fonts/CircularStd-BlackItalic.otf') format('otf');
     font-weight: black;
     font-style: italic;
}
