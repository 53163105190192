/* Stats Section */

.statscard{
    cursor: pointer;
    padding: 40px 0;
    border-radius: 10px;
    filter: drop-shadow(0px 3px 10px rgba(0,0,0,0.3));
    background-image: linear-gradient(to right, #61ACCA, #8364C3);
}

@media not all and (min-width: 640px) {
    .statscard{
        padding: 30px 10px;
    }
}

/* About Us Section */

.homeaboutus .slick-arrow::before,.homeaboutus .slick-arrow{
    display: none !important;
}

/* Our Solution Section */

.oursolutionbg{
    z-index: -3;
    width: 100%;
    left: 745px;
    top: 20px;
    height: 900px;
    position: absolute;
    background-size:200px 100px;
    background-repeat: no-repeat;
    background-image: url('https://civilmantra.s3.ap-south-1.amazonaws.com/Saptagon-website-images/SaptagonAssets/Home/OurSolutionbg.png');
}    


/* Filter Services Section */

.home-tabs{
    padding: 8px 20px;
    font-size: 1rem;
    color: var(--darkgray);
}

.home-active-tabs{
    color: #fff;
    border-radius: 999px;
    background-image: linear-gradient(to right, #61ACCA, #8364C3);
}

.slick-slide {
    margin: 10px 0 0 0 !important;
}

.slick-dots{
    bottom: -70px !important;
}

@media not all and (min-width: 640px) {       
    .home-tabs{
        padding: 8px 25px;
    }
    .slick-dots{
        display: none !important;
    }
}


.filterservicebgright{
    z-index: -5;
    top: 190px;
    left:1250px;
    width: 100%;
    height: 100%;
    position: absolute;
    background-size:130px 130px;
    background-repeat: no-repeat;
    background-image: url('https://civilmantra.s3.ap-south-1.amazonaws.com/Saptagon-website-images/SaptagonAssets/Home/FilterServicebg.png');
}

.filterservicebgleft{
    z-index: -5;
    top: 850px;
    left:140px;
    width: 100%;
    height: 100%;
    position: absolute;
    background-size:130px 130px;
    background-repeat: no-repeat;
    background-image: url('https://civilmantra.s3.ap-south-1.amazonaws.com/Saptagon-website-images/SaptagonAssets/Home/FilterServicebg.png');
}

/** Cards Gradient Overlay Effect **/

.first:hover .second .gradient-overlay {
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.7) 100%);
}

.first:hover .third {
    bottom: 1rem;
    opacity: 1;
}

.gradient-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transition: all 0.3s ease-in-out;
}

.third {
    transition: bottom 0.3s ease-in-out, opacity 0.3s ease-in-out;
}


/* Get in Touch Section */

.getintouchbg{
    width: 100%;
    height: 100%;
    padding: 30px 0;
    border-top-left-radius: 50px;
    border-top-right-radius: 50px;
    background-image: linear-gradient(to right, #61ACCA, #8364C3);
}

@media not all and (min-width: 640px) {
        
    .getintouchbg{
        z-index: -7;
        width: 100%;
        border-radius: 20px;
        background-image: linear-gradient(to right, #61ACCA, #8364C3);
    }

}



/* Testimonial Section */

.testimonialbg{
    z-index: -6;
    width: 100%;
    height: 100%;
    left: 820px;
    top: 0px;
    position: absolute;
    background-size:250px 120px;
    background-repeat: no-repeat;
    background-image: url('https://civilmantra.s3.ap-south-1.amazonaws.com/Saptagon-website-images/SaptagonAssets/Home/OurSolutionbg.png');
}

.swiper-pagination {
    margin: 20px 0 0 0;
}

.swiper-wrapper{
    margin: 0 0 5px 0;
}

.swiper-horizontal{
    padding: 50px 70px !important;
}

@media not all and (min-width:640px) {
    .swiper-horizontal{
        padding: 0px 40px 70px 40px !important;
    }    
}

/* Our Clients Section */

.clientsbg{
    z-index: -7;
    width: 100%;
    height: 100%;
    left: 44%;
    top: 19%;
    position: absolute;
    background-size:280px 105px;
    background-repeat: no-repeat;
    background-image: url('https://civilmantra.s3.ap-south-1.amazonaws.com/Saptagon-website-images/SaptagonAssets/Home/OurSolutionbg.png');
}

/* Mobile Styling */
.swiper-wrapper{
    display: flex;
    align-items: center;
    justify-content: center;
}

.ourclients .slick-slider{
    padding: 20px 0 0 0;
}
.ourclients .slick-arrow::before{
    display: none;
}

.ourclients .slick-next{
    width: 5vw;
    top: -20px;
    right: 20px !important;
}

.ourclients .slick-prev{
    width: 5vw;
    top: -20px;
    left: 340px !important;
}

.ourclients .slick-arrow::before, .ourclients2 .slick-arrow::before{
    display: none;
}


/* Desktop Styling */

.ourclients2 .slick-next{
    width: 2vw;
    right: -50px !important;
}

.ourclients2 .slick-prev{
    width: 2vw;
    left: -50px !important;
}

@media not all and (min-width:640px) {    
    .ourclients2 .slick-next, .ourclients2 .slick-prev{
        display: none !important;
    }
}

/* Contact Us */

.contactcont{
    background-image: url("../Assets/Home/ContactBG.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}



.fade-in {
    animation: fadeIn 1.5s ease-in-out forwards;
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  .btn-hover:hover {
    transform: scale(1.1);
    transition: transform 0.3s ease;
  }
  



  /* Fade-in Animation */
.fade-in {
    animation: fadeIn 1.5s ease-in-out forwards;
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  /* Slide-in Animation */
  .slide-in {
    animation: slideIn 1s ease-in-out forwards;
  }
  
  @keyframes slideIn {
    from {
      opacity: 0;
      transform: translateX(-30px);
    }
    to {
      opacity: 1;
      transform: translateX(0);
    }
  }
  
  /* Button Hover Animation */
  .btn-hover:hover {
    transform: scale(1.1);
    transition: transform 0.3s ease;
  }
  



  /* Fade-in Animation */
.fade-in {
    animation: fadeIn 1s ease-in-out forwards;
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  /* Slide-in from Left */
  .slide-in-left {
    animation: slideInLeft 1s ease-in-out forwards;
  }
  
  @keyframes slideInLeft {
    from {
      opacity: 0;
      transform: translateX(-30px);
    }
    to {
      opacity: 1;
      transform: translateX(0);
    }
  }
  
  /* Slide-in from Right */
  .slide-in-right {
    animation: slideInRight 1s ease-in-out forwards;
  }
  
  @keyframes slideInRight {
    from {
      opacity: 0;
      transform: translateX(30px);
    }
    to {
      opacity: 1;
      transform: translateX(0);
    }
  }
  
  /* Scale-in Center */
  .scale-in-center {
    animation: scaleInCenter 0.7s ease-in-out forwards;
  }
  
  @keyframes scaleInCenter {
    from {
      opacity: 0;
      transform: scale(0.9);
    }
    to {
      opacity: 1;
      transform: scale(1);
    }
  }
  