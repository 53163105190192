.lab-tabs2{
    width: 20vw;
    font-weight: 500;
    color: var(--darkblue);
    padding: 35px 20px;
    border-radius: 10px;
    background: rgba(231, 231, 231, 0.4);
}

.lab-active-tabs2{
    color: #fff;
    font-weight: 600;
    background: #8364C3;
}



@media not all and (min-width:640px){
    .lab-tabs2{
        min-width: 55vw;
        padding: 15px 10px;
    }
}