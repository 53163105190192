/* Hero Section */

.aboutherobg{
    top: -20px;
    left: 700px;
    z-index: -1;
    width: 100%;
    height: 100%;
    position: absolute;
    background-size:800px 650px;
    background-repeat: no-repeat;
    background-image: url('https://civilmantra.s3.ap-south-1.amazonaws.com/Saptagon-website-images/SaptagonAssets/About/AboutHerobg.png');
}

.aboutsplashbg{
    z-index: -2;
    width: 100%;
    height: 100%;
    left: 30px;
    top: 110px;
    position: absolute;
    background-size:400px 170px;
    background-repeat: no-repeat;
    background-image: url('https://civilmantra.s3.ap-south-1.amazonaws.com/Saptagon-website-images/SaptagonAssets/Home/OurSolutionbg.png');
}


/* About Section */

.aboutusbg{
    z-index: 1;
    width: 100%;
    height: 100%;
    left: 60px;
    position: absolute;
    background-size:520px 500px;
    background-repeat: no-repeat;
    background-image: url('https://civilmantra.s3.ap-south-1.amazonaws.com/Saptagon-website-images/SaptagonAssets/About/Aboutusbg.png');
}

.aboutuscirclebg{
    z-index: -5;
    top: 0;
    left:36%;
    width: 100%;
    height: 100%;
    position: absolute;
    background-size:130px 130px;
    background-repeat: no-repeat;
    background-image: url('https://civilmantra.s3.ap-south-1.amazonaws.com/Saptagon-website-images/SaptagonAssets/Home/FilterServicebg.png');
}


/* Quality Policy Section */

.qualitypolicybg{
    z-index: -1;
    width: 100%;
    height: 100%;
    left: 1000px;
    top: 50px;
    position: absolute;
    background-size:440px 520px;
    background-repeat: no-repeat;
    background-image: url('https://civilmantra.s3.ap-south-1.amazonaws.com/Saptagon-website-images/SaptagonAssets/About/QualityPolicybg.png');
}

.aboutsplashbg2{
    z-index: -2;
    width: 100%;
    height: 100%;
    left: 125px;
    top: 125px;
    position: absolute;
    background-size:250px 110px;
    background-repeat: no-repeat;
    background-image: url('https://civilmantra.s3.ap-south-1.amazonaws.com/Saptagon-website-images/SaptagonAssets/Home/OurSolutionbg.png');
}


/* Our Team Section */

.ourteambg{
    z-index: -2;
    width: 100%;
    height: 100%;
    position: absolute;
    background-size:1520px 1000px;
    background-repeat: no-repeat;
    background-image: url('https://civilmantra.s3.ap-south-1.amazonaws.com/Saptagon-website-images/SaptagonAssets/About/OurTeambg.png');
}

.aboutsplashbg3{
    z-index: -1;
    width: 100%;
    height: 100%;
    left: 680px;
    top: 120px;
    position: absolute;
    background-size:250px 100px;
    background-repeat: no-repeat;
    background-image: url('https://civilmantra.s3.ap-south-1.amazonaws.com/Saptagon-website-images/SaptagonAssets/About/OurTeamSplash.png');
}

.swiper {
    width: 100%;
    height: 100%;
}

.our-teamslider .swiper-slide {
    width: 42% !important;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}    

/* Gallery Section */
/** Cards Gradient Overlay Effect **/

.first2:hover .second .gradient-overlay2 {
    background: rgba(0, 0, 0, 0.5);
}

.first2:hover .third2 {
    bottom: 1rem;
    opacity: 1;
}

.gradient-overlay2 {
    height: 20vh;
    position: absolute;
    top: 30%;
    left: 0;
    right: 0;
    bottom: 0;
    transition: all 0.3s ease-in-out;
}

.third2 {
    transition: all 0.3s ease-in-out;
}


/* Our Objective Section */

.ourobjectivebg{
    z-index: -7;
    width: 100%;
    height: 100%;
    left: 900px;
    top: 50px;
    position: absolute;
    background-size:570px 540px;
    background-repeat: no-repeat;
    background-image: url('https://civilmantra.s3.ap-south-1.amazonaws.com/Saptagon-website-images/SaptagonAssets/About/OurObjectivebg.png');
}

.aboutsplashbg4{
    z-index: -1;
    width: 100%;
    height: 100%;
    left:210px;
    top: 130px;
    position: absolute;
    background-size:250px 100px;
    background-repeat: no-repeat;
    background-image: url('https://civilmantra.s3.ap-south-1.amazonaws.com/Saptagon-website-images/SaptagonAssets/Home/OurSolutionbg.png');
}

/* Our Vision Section */

.ourvisionbg{
    z-index: -7;
    width: 100%;
    height: 100%;
    left: 60px;
    position: absolute;
    background-size:620px 480px;
    background-repeat: no-repeat;
    background-image: url('https://civilmantra.s3.ap-south-1.amazonaws.com/Saptagon-website-images/SaptagonAssets/About/OurVisionbg.png');
}