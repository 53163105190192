.home-tabs2{
    padding: 10px 25px;
    font-size: 1rem;
    font-weight: 500;
    color: var(--darkgray);
}

.home-active-tabs2{
    color: #fff;
    background-image: linear-gradient(to right, #61ACCA, #8364C3);
}

@media not all and (min-width: 640px) {
    .home-tabs2,.home-tabs{
        font-size: 0.7rem;
        font-weight: 500;
    }

    .tabs-container {
        gap: 8px;
        display: flex;
        overflow-x: auto;
        white-space: nowrap;
    }
    
    .tabs-container::-webkit-scrollbar {
        display: none;
    }

}

.projectpage .swiper-horizontal{
    padding: 0 50px !important;
}

/* Pagination */
.pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
  }
  
  .page-number, .page-arrow {
    width: 30px;
    height: 30px;
    border: none;
    color: var(--darkgray);
    cursor: pointer;
    font-size: 16px;
    border-radius: 100%;
    background-color: transparent;
    transition: background-color 0.3s ease;
  }

  .page-arrow{
    border: 1px solid #cccccc73;
  }


  .page-number:hover, .page-arrow:hover {
    background-color: #f0f0f0;
  }
  
  .page-number.active {
    background-color: var(--mainpurple);
    color: #fff;
  }
  
  .page-arrow:disabled {
    cursor: not-allowed;
    color: #ccc;
  }
  

  /* Cards Hover Overlay Effect */
  .card:hover .card-details {
    opacity: 1;
    transform: translateY(0%);
    transition: opacity 0.3s ease-in-out, transform 0.5s ease-in-out;
  }
  
  .card-image {
    position: relative;
    overflow: hidden;
    transition: transform 0.3s ease-in-out;
  }
  
  .gradient-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, rgba(0,0,0,0.1), rgba(0,0,0,0.3));
  }
  
  .card-details {
    transform: translateY(20%);
    width: 100%;
    padding: 30px 20px;
    background: rgba(255, 255, 255, 1);
    transition: opacity 0.3s ease-in-out, transform 0.5s ease-in-out;
  }

  @media not all and (min-width: 640px) {
    .card-details {
      padding: 15px;
    }
  }
  
  .no-scroll {
    overflow: hidden;
  }
  