:root{
    --mainblue: #61ACCA;
    --highlightblue: #4FBECD;
    --mainpurple: #8364C3;
    --darkgray: #696B6E;
    --lightgray: #9DA6AF;
    --darkblue: #02073E;
    --black: #000000;
    --footer: #213140;
  }

*::selection{
  color: var(--mainblue);
  background: #fff;
}
*{
  outline: none;
}
*::-webkit-scrollbar {
  display: none;
}
.group:hover img{
  filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(0%) hue-rotate(0deg);
}
.grace{
  font-family: "Covered By Your Grace", cursive;
}
.circularstd{
    font-family: 'Circularstd', sans-serif;
}
.active-link{
  color:var(--mainpurple);
}
.blue{
  color: var(--mainblue);
}
.highlightblue{
  color: var(--highlightblue);
}
.purple{
  color: var(--mainpurple);
}
.darkgray{
  color:var(--darkgray);
}
.lightgray{
  color:var(--lightgray);
}
.darkblue{
  color: var(--darkblue);
}
.black{
  color: var(--black);
}
.btn{
  background: linear-gradient(to right, #61ACCA, #8364C3);
}
.footer-icons{
  width: 40px;
  height: 40px;
  border-radius: 50px;
  background: var(--mainpurple);
  transition: 0.2s all linear;
}
.footer-icons:hover{
  background: var(--mainblue);
}

.footer-cont{
  height: 60%;
  width: 100%;
  z-index: 1;
  overflow: hidden;
  background-size:cover;
  background-repeat: no-repeat;
  background-image: url('https://civilmantra.s3.ap-south-1.amazonaws.com/Saptagon-website-images/SaptagonAssets/Footer/footerbg.svg'); 
}
